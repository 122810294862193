/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(document).foundation();

        //Disable Default click behaviour on anchor
        // $(".search-icon-toggle").on("click", function(event) { 
        //   event.preventDefault();
        // });

        //scroll effect
        $("#top").on("click",function () {
          $("html, body").animate({ scrollTop: 0 }, "slow");
          return false;
        });

        $("#top").on("click",function (event) {
          event.stopPropagation();                
          var idTo = $(this).attr("data-atr");                
          var Position = $("[id='" + idTo + "']").offset();
          $("html, body").animate({ scrollTop: Position }, "slow");
          return false;
        });

        $(window).on("scroll",function() { 
          if($(this).scrollTop() > 1000) { 
            $("#top").fadeIn();
          } else { 
            $("#top").fadeOut();
          }
        });

        //Loading animation js
        $(window).on("load",function(){ 
          $("#loading").addClass("loaded");
          $("#loading").html('');
        });

        //Animation effect on teacher social links
        $(".teacher").on("mouseenter",function() {
          var animationEnd = "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend";
          $(this).children(".teacher-thumb").children(".teacher-links").addClass("animated zoomIn").on(animationEnd, function() {
          $(this).removeClass("animated zoomIn");
          });
        });

        //Calling Teacher Crousel
        $(".teachers-wrapper").owlCarousel({
          loop:true,
          margin:40,
          responsiveClass:true,
          responsive:{
            0:{
              items:1,
              nav:true,
              navText:["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"]
            },
            600:{
              items:3,
              nav:false,
              navText:["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"]
            },
            1000:{
              items:4,
              nav:true,
              navText:["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
              loop:true
            }
          }
        });

        //Calling Testimonials Crousel
        $(".testimonials").owlCarousel({
          loop:true,
          margin:40,
          responsiveClass:true,
          responsive:{
            0:{
              items:1,
              nav:true,
              navText:["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"]
            },
            600:{
              items:3,
              nav:false,
              navText:["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"]
            },
            1000:{
              items:2,
              nav:true,
              navText:["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
              loop:true
            }
          }
        });
  
        //calling Brand Crousel
        $(".brand-carousel").owlCarousel({
          loop:true,
          margin:60,
          responsiveClass:true,
          responsive:{
            0:{
              items:1,
              nav:true,
              navText:["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"]
            },
            600:{
              items:3,
              nav:false,
              navText:["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"]
            },
            1000:{
              items:5,
              nav:true,
              navText:["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
              loop:true
            }
          }
        });

        
        $('a[href*="#"]:not([href="#"]):not(#top)').click(function() {
          if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
              $('html, body').animate({
                scrollTop: target.offset().top - 50
              }, 1000);
              return false;
            }
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Members Page
    'memberst': {
      init: function() {
        // JavaScript to be fired on the members page
        var options = {
          valueNames: [ 'name', 'city' ]
        };

        var memberList = new List('members-listing', options);
      },
      finalize: function() {
        // JavaScript to be fired on the members page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
